import request from '@/utils/request'
import { version } from '/package.json'
const { dataset } = document.currentScript

window.quidget = { isDestroyed: false }
request(`${process.env.VUE_APP_WEBCHAT_URL}/files.json?v=${version}`)
  .then(files => {
    if (!files || !Array.isArray(files) || files.length === 0) {
      throw 'files not found'
    }

    const js = files.find(name => /^quidget\.[a-f0-9]{8,16}\.js$/.test(name))
    if (!js) {
      throw 'script not found'
    }

    // insert HTML variant
    const app = document.createElement('script')
    app.async = true
    app.src = `${process.env.VUE_APP_WEBCHAT_URL}/${js}`
    ;(document.head || document.documentElement).appendChild(app)
    app.onload = () => {
      // document.addEventListener('DOMContentLoaded', event => {
      //   return window.quidget.init(dataset)
      // })

      if (document.readyState === 'complete') {
        return !window.quidget.isDestroyed && window.quidget.init(dataset)
      } else {
        document.onreadystatechange = () => {
          if (document.readyState === 'complete') {
            return !window.quidget.isDestroyed && window.quidget.init(dataset)
          }
        }
      }
    }
  })
  .catch(err => console.warn(`💬 fail on load quidget ${JSON.stringify(err)}`, err))
